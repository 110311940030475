<script>
import AppFooter from '@/components/AppFooter'

export default {
  name: 'predaj',
  components: {
    AppFooter
  },
  methods: {
    scrollTo(ref) {
      const element = this.$refs[ref].$el
      window.scrollTo(0, element.offsetTop - 120);
    },
    openModal(n) {
      this.$refs[`modal${n}`].open()
    }
  }
};
</script>

<template>
  <div class="pozicovna-view">
    <article>
      <div class="content-wrapper">
        <h1>PREDAJ</h1>
        
        <h2>Predaj lyží, snowboardov a ďalšej výstroje</h2>
        
        <p>V našej predajni nájdete širokú a kvalitnú ponuku použitého lyžiarskeho a snowboardového vybavenia. Náš vyškolený personál vám pomôže vybrať výstroj podľa Vašich predstáv, ktorá bola väčšinou používaná maximálne 1 rok, je vo výbornom stave a po kompletnom servise na našich najmodernejších strojoch Montana. Dostupné sú u nás všetky značky a veľkosti, a čo je hlavné, za <b>SUPER CENY</b>. Na predávaný tovar poskytujeme záruku 1 rok. Povýšte Váš čas na snehu na dokonalý zážitok.</p>
      </div>

      <div class="boxes">
        <div @click="openModal(1)" class="box" style="background-color:#0968aa">
          <Imgix src="predaj/vip-race.jpg" alt="Zjazdové lyže VIP - RACE" loading="lazy" />
          <h3 style="background:rgba(9, 104, 170, 0.7)">Zjazdové lyže VIP - RACE</h3>
          <p>Prémiové modely z predných strán katalógov, ktoré sú určené hlavne výkonostne orientovaným lyžiarom a tým najväčším "fajnšmekrom".</p>
          <sweet-modal title="Zjazdové lyže VIP - RACE" ref="modal1">
            <aside>
              <Imgix src="predaj/predaj-vip.jpg" alt="Zjazdové lyže VIP - RACE" />
            </aside>
            <p>Prémiové modely z predných strán katalógov, ktoré sú určené hlavne výkonostne orientovaným lyžiarom a tým, čo hľadajú najpokročilejšie technológie.</p>
            <p class="price">Cena: 200 - 300 &euro;</p>
          </sweet-modal>
        </div>

        <div @click="openModal(2)" class="box" style="background-color:#01a0e2">
          <Imgix src="predaj/top.jpg" alt="Zjazdové lyže TOP" loading="lazy" />
          <h3 style="background:rgba(1, 160, 226, 0.5)">Zjazdové lyže TOP</h3>
          <p>Výkonostne a športovo orientované modely s vynikajúcim pomerom cena/výkon.</p>
          <sweet-modal title="Zjazdové lyže TOP" ref="modal2">
            <aside>
              <Imgix src="predaj/predaj-top.jpg" alt="Zjazdové lyže TOP" />
            </aside>
            <p>Výkonostne a športovo orientované modely s vynikajúcim pomerom cena/výkon.</p>
            <p class="price">Cena: 100 - 200 &euro;</p>
          </sweet-modal>
        </div>

        <div @click="openModal(3)" class="box" style="background-color:#41ead4">
          <Imgix src="predaj/economy.jpg" alt="Zjazdové lyže ECONOMY" loading="lazy" />
          <h3 style="background:rgba(65, 234, 212, 0.75)">Zjazdové lyže ECONOMY</h3>
          <p>Modely určené pre rekreačných a začínajúcich lyžiarov.</p>
          <sweet-modal title="Zjazdové lyže ECONOMY" ref="modal3">
            <aside>
              <Imgix src="predaj/predaj-eco.jpg" alt="Zjazdové lyže ECONOMY" />
            </aside>
            <p>Modely určené pre rekreačných a začínajúcich lyžiarov.</p>
            <p class="price">Cena: 60 - 100 &euro;</p>
          </sweet-modal>
        </div>

        <div @click="openModal(4)" class="box" style="background-color:#ff9f1c">
          <Imgix src="predaj/detske.jpg" alt="Detské lyže" loading="lazy" />
          <h3 style="background:rgba(255, 159, 28, 0.6)">Detské lyže</h3>
          <p>Kvalitné lyže pre deti vo všetkých dostupných veľkostiach.</p>
          <sweet-modal title="Detské lyže" ref="modal4">
            <aside>
              <Imgix src="predaj/predaj-detske.jpg" alt="Detské lyže" />
            </aside>
            <p>Kvalitné lyže pre deti vo všetkých dostupných veľkostiach.</p>
            <p class="price">Cena: 50 - 100 &euro;</p>
          </sweet-modal>
        </div>

        <div @click="openModal(5)" class="box" style="background-color:#96d400">
          <Imgix src="predaj/topanky.jpg" alt="Lyžiarske a snowboard topánky" loading="lazy" />
          <h3 style="background:rgba(150, 212, 0, 0.4)">Lyžiarske a snowboard topánky</h3>
          <p>Široká ponuka lyžiarskych topánok všetkých veľkostí pre deti, začínajúcich lyžiarov, pokročilých i expertov. Všetky topánky sú hygienicky ošetrené.</p>
          <sweet-modal title="Lyžiarske topánky" ref="modal5">
            <aside>
              <Imgix src="predaj/predaj-topanky.jpg" alt="Lyžiarske a snowboard topánky" />
            </aside>
            <p>Široká ponuka lyžiarskych topánok všetkých veľkostí pre deti, začínajúcich lyžiarov, pokročilých i expertov. Všetky topánky sú hygienicky ošetrené.</p>
            <p class="price">Cena: 50 - 80 &euro;</p>
          </sweet-modal>
        </div>

        <div @click="openModal(6)" class="box" style="background-color:#f71735">
          <Imgix src="predaj/snowboard.jpg" alt="Snowboard a ďalšie vybavenie" />
          <h3 style="background:rgba(247, 23, 53, 0.5)">Snowboard a ďalšie vybavenie</h3>
          <p>Ďalšia zimná výbava z našej ponuky: snowboardy, bežecké lyže a bežecká obuv, bigfoot a mnoho ďalšieho.</p>
          <sweet-modal title="Snowboard a ďalšie vybavenie" ref="modal6">
            <aside>
              <Imgix src="predaj/predaj-snowboard.jpg" alt="Snowboard a ďalšie vybavenie" />
            </aside>
            <p>Ďalšia zimná výbava z našej ponuky:<br>
             - snowboardy,<br>
             - bežecké lyže a bežecká obuv,<br>
             - bigfoot, snowblade, sánky,<br>
             - lyžiarske a snowboardové prilby,<br>
             - lyžiarske a slnečné okuliare,<br>
             - zimné čiapky, rukavice<br>
             &hellip; a mnoho ďalšieho</p>
          </sweet-modal>
        </div>
      </div>

      <div class="full-box bg-predajna">
        <div class="full-box-content">
          <h3 style="color: #96d400">Kde nájdete našu predajňu</h3>
          <p>Jánošíkovo nábrežie 6<br>
              032 02 Liptovský Mikuláš<br>
              (pri objekte Gastro Glass Tatry, 200 m od Hotela Jánošík)</p>
          <br>
          <p><font-awesome-icon icon="phone-alt" /> +421 905 357 133</p>
          <br>
          <p><router-link to="/kontakt/1" class="button">Viac info</router-link></p>
        </div>
      </div>

      <div class="full-box full-box-predaj-img">
        <div class="full-box-content" style="text-align:center">
          <Imgix src="predaj/predaj.jpg" alt="Predajňa" loading="lazy" />
        </div>
      </div>
    </article>
    <app-footer>
      <b>Požičovňa lyžiarskej a snowboardovej výstroje:</b>
        <ul>
          <li><a @click="scrollTo('1')">VIP zjazdové lyže</a></li>
          <li><a @click="scrollTo('2')">STANDARD zjazdové lyže</a></li>
          <li><a @click="scrollTo('3')">DETSKÉ/JUNIOR zjazdové lyže</a></li>
          <li><a @click="scrollTo('4')">Snowboard</a></li>
          <li><a @click="scrollTo('5')">Bežecká výstroj</a></li>
          <li><a @click="scrollTo('6')">Skialpová výstroj</a></li>
          <li><a @click="scrollTo('7')">Ostatné vybavenie</a></li>
        </ul>
    </app-footer>
  </div>
</template>

<style lang="scss" scoped>
  $title-color: #0968aa;

  .bg-predajna {
    background-image: url(https://skiservis.imgix.net/predaj/predajna.jpg?auto=format&dpr=1);
    background-color: #f5f5f7;

    @media only screen and (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        background-image: url(https://skiservis.imgix.net/predaj/predajna.jpg?auto=format&dpr=2);
    }

    @media only screen and (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
        background-image: url(https://skiservis.imgix.net/predaj/predajna.jpg?auto=format&dpr=3);
    }
  }

  .content-wrapper {
    width: 90%;
    margin: 1rem auto;

    & > p {
      font-size: 18px;
      margin: 1rem 0;
    }
  }

  h1 {
    display: none;
  }

  h2 {
    color: $title-color;
    font-size: 30px;
    margin: 2.5rem 0 2rem;
    text-transform: uppercase;
  }

  .boxes {
    .box {
      box-shadow: 0px 1px 2px 0 rgba(0,0,0,.2);
      overflow: hidden;
      cursor: pointer;

      ::v-deep h3 {
        font-family: "Montserrat";
        font-size: 23px;
        color: #fff;
        margin: -3.5rem 0 0;
        padding: .5rem 1rem;
        text-transform: uppercase;
        position: relative;
        background: rgba(0,0,0,.5);
      }

      ::v-deep .sweet-content {
        .sweet-content-content {
          position: relative;
        }

        @media (min-width: 1024px) {
          aside {
            float: right;
            margin: -.5rem 0;

            img {
              margin-left: 20px;
            }
          }
        }

        p {
          color: #444;
          font-size: 1rem;
          padding: 1rem 0;
        }

        .price {
          color: #66acca;
          font-size: 22px;
          font-weight: bold;
          border: 1px solid #d9f3ff;
          padding: .25rem 1rem;
          display: inline-block;

          @media (min-width: 1024px) {
            position: absolute;
            bottom: 0;
          }
        }
      }

      ::v-deep .sweet-buttons {
        border: none;
        text-align: center;
        padding-bottom: 2rem;
      }

      ::v-deep .button-action {
        font-weight: normal;
        font-size: 1.1rem;
        border-radius: 2rem;
        padding: 0.5rem 1.5rem;
      }

      ::v-deep .sweet-modal .sweet-title > h2 {
        color: #66acca;
      }

      p {
        padding: 1rem;
        margin: 0;
        color: #fff;
      }
    }
  }

  .full-box {
    background-size: contain;
    padding: 2rem 3%;

    @media (max-width: 720px) {
      &.full-box-predaj-img {
        padding: 0;

        .full-box-content {
          padding: 0;
        }
      }
    }

    p {
      line-height: 2;
    }

    h3 {
      font-weight: 200;
      font-size: 30px;
      margin: 1rem 0;
    }
  }

  .button {
    font-weight: normal;
    font-size: 1rem;
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
    background: #66acca;
    color: #fff;
    text-transform: uppercase;
  }
</style>
